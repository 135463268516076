const optionsAr = { timeZone: 'Asia/Riyadh', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit',hour12 : true };
const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',hour12 : true };

export const dateFormate = (date)=>{
    if(date){
        let currentDate = new Date(date)
        // const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',hour12 : true };
        let newDateTime = localStorage.getItem('i18nextLng')==='ar' ? currentDate.toLocaleDateString('ar-EG', optionsAr) : currentDate.toLocaleString('en-GB', options);
        newDateTime = newDateTime.replace(/\//g, '-');
        newDateTime = newDateTime.replace(/am|pm/i, match => match.toUpperCase());

        return newDateTime
    }
}

export const fullDate = (date)=>{
    if(date){
        let currentDate = new Date(date)
        // const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',hour12 : true };
        // const optionsAr = { timeZone: 'Asia/Riyadh', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit',hour12 : true };
        let newDateTime = localStorage.getItem('i18nextLng')==='ar' ? currentDate.toLocaleDateString( 'ar-EG', optionsAr) : currentDate.toUTCString( 'en-US', options);
        newDateTime = newDateTime.replace(/am|pm/i, match => match.toUpperCase());

        // newDateTime = newDateTime.replace(/\//g, '-');
        // console.log(newDateTime);
        return newDateTime
    }
}

export const dateOnlyFormate = (date)=>{
    let currentDate = new Date(date)
    const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit' };
    const optionsAr = { timeZone: 'Asia/Riyadh', year: 'numeric', month: 'long', day: 'numeric'};
    let newDate = localStorage.getItem('i18nextLng')==='ar' ? currentDate.toLocaleDateString('ar-EG', optionsAr) : currentDate.toLocaleDateString('en-GB', options);
    newDate = newDate.replace(/\//g, '-');
    newDate = newDate.replace(/am|pm/i, match => match.toUpperCase());

    return newDate
}

export const timeOnlyFormate = (date)=>{

    let currentDate = new Date(date)
    const timeOptions = { timeZone: 'Asia/Riyadh', hour: '2-digit', minute:'2-digit', hour12:true };
    const timeOptionsAr = { timeZone: 'Asia/Riyadh', hour: '2-digit', minute:'2-digit', hour12:true };
    const newTime = localStorage.getItem('i18nextLng')==='ar' ? currentDate.toLocaleTimeString('ar-EG', timeOptionsAr) : currentDate.toLocaleTimeString('en-US', timeOptions);

    return newTime
}

export const timeToDateFormat = (time)=>{
    let timeString = time
    const [hours, minutes, seconds] = timeString.split(':');
    let date = new Date()
    date.setHours(hours,minutes,seconds)
    return date;
}

export const convertTo12Hour = (time)=> {
    let [hours, minutes] = time.split(":");
    let period = +hours < 12 ? 'AM' : 'PM';
    hours = +hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
}

export const subscripeDateFormat = (date)=>{
    const optionsAr = { timeZone: 'UTC', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12 : true };
    const options = { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' ,hour12 : true };

    if(date){
        let currentDate = new Date(date)
        // const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', ,hour12 : true };
        let newDateTime = localStorage.getItem('lang')==='ar' ? currentDate.toLocaleDateString('ar-EG', optionsAr) : currentDate.toLocaleString('en-GB', options);
        newDateTime = newDateTime.replace(/\//g, '-');

        return newDateTime
    }
}