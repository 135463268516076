import { Button, FormControl, IconButton, InputAdornment, InputLabel, ListItemButton, Menu, MenuItem, OutlinedInput, Select } from '@mui/material';
import {FaAngleDown, FaEye} from 'react-icons/fa'
import { useCallback, useEffect, useState } from 'react';
import {CiSearch} from 'react-icons/ci'
import DataTable from 'react-data-table-component';
import {BiDotsHorizontalRounded} from 'react-icons/bi'
import {AiFillStar} from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux';
import { openHandler } from '../../Components/Redux/Slice/common/overlaySlice';
import { Link, useNavigate } from 'react-router-dom';
import { getOrders, getOrdersTypes } from '../../Components/Redux/Slice/Orders/orders';
import { DateRangePicker } from 'react-date-range';
import { dateFormate } from '../../Components/Common/dateFormate';
import ColumnSelection from '../../Components/Common/columnSelection';
import { useRemoteSort } from '../../Components/Common/sortHook';
import NoDataEmoji from '../../Components/Common/nodataEmoje';
import { TableStyles } from '../../Components/Common/tableStyle';
import { useTranslation } from 'react-i18next';
import { resetPage } from '../../Components/Redux/Slice/ResetPagination/resetPagination';

//Custome Call UI for the data table action column
function ActionCell({data}) {
    // const [showList, setshowList] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log(data);
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0} }}
                className='shadow-md p-0'
                sx={{'& .MuiList-padding': {p:0}}}
            >
                <div className='[&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem className={`text-[#6993FF] hover:bg-[#6994ff42] ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}><Link to='orderdetails' state={{id:data?.id}} className={`w-full flex items-center gap-x-3 ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}><FaEye />{localStorage.getItem('i18nextLng') === 'ar' ? 'عرض تفاصيل الطلب' : 'view order details'}</Link></MenuItem>
                    {/* <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> Contact restaurant</MenuItem> */}
                    {/* <MenuItem className='text-mainColor hover:bg-[#442b7e42]'><AiOutlineCar />Contact Courier</MenuItem> */}
                    {/* <MenuItem className='text-[#577bd5] hover:bg-[#6994ff42]'><LuContact />Contact Customer</MenuItem> */}
                    {/* <MenuItem className='text-mainYellow hover:bg-[#d9ad5642]'><FaBarcode />Order Items</MenuItem> */}
                    {/* <MenuItem className='text-[#3699FF] hover:bg-[#369bff42]'><RiMoneyDollarCircleLine />Refund or Cashback</MenuItem> */}
                    {/* <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><IoBagHandleOutline />Cancel order</MenuItem> */}
                </div>
            </Menu>
        </div>
    );
}

const Orders = () => {
    const {t,i18n} = useTranslation()
    const [showCalander,setshowCalender] = useState(false) //for displaying the date picker when button clicked
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    const [region, setregion] = useState(""); // for storing the Region
    const [orderType, setorderType] = useState(null); // for storing the type
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    const [searchValue, setsearchValue] = useState('')
    const [col, setcol] = useState(undefined)
    const [dir, setdir] = useState('asc')
    const navigate = useNavigate()

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle} = useSelector((state) => state?.resetPagination);

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    const dispatch = useDispatch()
    const {orders} = useSelector(state=>state)

    // console.log(orders?.ordersType);

    useEffect(() => {
        dispatch(getOrders({page,size}))
        dispatch(getOrdersTypes())
    }, [])

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };
    useEffect(() => {
        dispatch(getOrders({page,size,col,dir,searchValue,selectedDate,orderType}))
    }, [dispatch, page,size,searchValue])
    
    // console.log(orders);


    // const handleDateChange = (date) => {
    //     setSelectedDate(date);
    //     dispatch(closeHandler(false))
    //     // console.log(date.$M+1);
    // };

    // // console.log(selectedDate);
    // const handleRegionChange = (event) => {
    //     setregion(event.target.value);
    // };

    const handleTypeChange = (event) => {
        let orderType = event.target.value 
        // settype({id:val});
        setorderType(orderType);

        dispatch(getOrders({page,size,searchValue,col,dir,orderType,selectedDate}))
    };

    // console.log(selectedDate.$M+1); display month but add 1 since it start with month 0

    //data table section
    const columns = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
            sortable: false,
            width:'80px'
        },
        {
            id: 'CourierName',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اسم السائق' : 'Courier Name'}`,
            selector: row => row.courierName,
            sortable: true,
        },
        {
            id: 'CourierNumber',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'رقم السائق' : 'Courier Number'}`,
            selector: row => row?.courierMobileNumber,
            sortable: true,
        },
        {
            id: 'courierRating',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'تقييم السائق' : 'Courier Rating'}`,
            selector: row => <div className='flex' data-tag="allowRowEvents"> {row?.courierRating ? [...Array(row?.courierRating)].map( (_,index)=> <AiFillStar key={index} className='text-mainYellow' /> ) : t('common.notRated')} </div>,
            sortable: true,
        },
        {
            id: 'CreatedAt',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'تم انشاءه في' : 'Created at'}`,
            cell: row => <div data-tag="allowRowEvents" title={dateFormate(row.createdAt)}>{dateFormate(row.createdAt)}</div>,
            sortable : true
        },
        {
            id: 'DeliveredAt',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'تم التوصيل في' : 'Delieverd at'}`,
            cell: row => <div data-tag="allowRowEvents" title={dateFormate(row.deliveredAt)}>{dateFormate(row.deliveredAt)}</div>,
            sortable : true,
            minWidth: '160px'
        },
        {
            id: 'orderRating',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'تقييم الطلب' : 'Order Rating'}`,
            selector: row => <div className='flex' data-tag="allowRowEvents"> {row?.orderRating ? [...Array(row?.orderRating)].map( (_,index)=> <AiFillStar key={index} className='text-mainYellow' /> ) : t('common.notRated')} </div>,
            sortable: true,
        },
        {
            id: 'orderStatus',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'حالة الطلب' : 'Order Status'}`,
            cell: row => <span data-tag="allowRowEvents" className={`capitalize bg-opacity-10 font-semibold px-2 py-1 rounded-md min-w-[100px] text-center block text-[15px] ${row.orderStatus === 'OUT_FOR_DELIVERY' ? 'text-mainYellow bg-mainYellow' : row.orderStatus === 'READY_FOR_PICKUP' ? 'text-[#a2c948] bg-[#a2c948]' : row?.orderStatus === 'CONFIRMED' ? 'text-mainGreen bg-mainGreen' : row?.orderStatus === 'IN_THE_KITCHEN' ? 'text-mainRed bg-mainRed' : row?.orderStatus === 'DELIVERED' ? 'text-mainColor bg-mainColor' : row?.orderStatus === 'ABANDONED' ? 'text-[#b45757] bg-[#b45757]' : row?.orderStatus === 'NEW' ? 'text-[#d6cc7e] bg-[#d6cc7e]' : row?.orderStatus === 'CANCELLED' ? 'text-[#474747] bg-[#474747]' : row?.orderStatus === 'PICKED_UP' ? 'text-[#8ce4f0] bg-[#8ce4f0]' : row?.orderStatus === 'REJECTED' ? 'text-[#bd5796] bg-[#bd5796]' : row?.orderStatus === 'SUBMITTED' ? 'text-[#76a0bd] bg-[#76a0bd]' : ''}`}>
                        {
                            row.orderStatus === 'OUT_FOR_DELIVERY' ? t('common.outForDelivery') : 
                            row.orderStatus === 'CONFIRMED' ? t('common.confirmed') : 
                            row.orderStatus === 'IN_THE_KITCHEN' ? t('common.inTheKitchen') : 
                            row.orderStatus === 'DELIVERED' ? t('common.delivered') : 
                            row.orderStatus === 'ABANDONED' ? t('common.abandoned') : 
                            row.orderStatus === 'NEW' ? t('common.new') :  
                            row.orderStatus === 'CANCELLED' ? t('common.cancelled') :  
                            row.orderStatus === 'PICKED_UP' ? t('common.pickedup') :  
                            row.orderStatus === 'REJECTED' ? t('common.rejected') :  
                            row.orderStatus === 'READY_FOR_PICKUP' ? t('common.redyForPickUp') :  
                            row.orderStatus === 'SUBMITTED' ? t('common.submitted') : null 

                        }
                    </span>,
            sortable: false,
        },
        {
            id: 'PickupSchedule',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'وقت الاستلام' : 'Pickup Schedule'}`,
            cell: row => <span data-tag="allowRowEvents">{row.pickupSchedule}</span>,
            sortable: true,
        },
        {
            id: 'VehiclePlate',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'لوحه المركبة' : 'Vehicle Plate'}`,
            cell: row => <span data-tag="allowRowEvents">{row.vehiclePlate}</span>,
            sortable: true,
        },
        {
            id: 'CustomerName',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اسم العميل' : 'Customer Name'}`,
            selector: row => row?.customerName,
            sortable: true,
        },
        {
            id: 'CustomerNumber',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'رقم العميل' : 'Customer Number'}`,
            selector: row => row?.customerMobileNumber,
            sortable: true,
        },
        {
            id: 'CustomerAddress',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'عنوان العميل' : 'Customer Address'}`,
            selector: row => row?.customerAddress,
            sortable: true,
        },
        {
            id: 'deliveryCharges',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'رسوم التوصيل' : 'Delivery Charges'}`,
            cell: row => <span data-tag="allowRowEvents" className='grow text-center'>{row.deliveryCharges} <span className='text-mainGreen font-semibold'>{t('common.sar')}</span></span>,
            sortable: false,
            grow : 1,
            justifyContent : 'center',
            className : 'delivery'
        },
        {
            id: 'OriginalPrice',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'السعر الاساسى' : 'Original Price'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.originalPrice} <span className='text-mainGreen font-semibold'>{t('common.sar')}</span></span>,
            sortable: true,
        },
        {
            id: 'FinalPrice',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'السعر النهائي' : 'Final Price'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.finalPrice} <span className='text-mainGreen font-semibold'>{t('common.sar')}</span></span>,
            sortable: true,
        },
        {
            id: 'BranchName',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اسم الفرع' : 'Branch Name'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchName} </span>,
            sortable: true,
        },
        {
            id: 'BranchNumber',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'رقم الفرع' : 'Branch Number'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchNumber} </span>,
            sortable: true,
        },
        {
            id: 'BranchAddress',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'عنوان الفرع' : 'Branch Address'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchAddress} </span>,
            sortable: true,
        },
        {
            id: 'Actions',
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>(
                <>
                    {<ActionCell data={row} />}
                </>
            )
        },
    ];
    

    const data = orders?.orders?.data
    // console.log(data);
    // open calander overlay
    const {dateOverlay}= useSelector(state=>state.overlay)

    const handleButton = ()=>{
        dispatch(openHandler({dateShow:!dateOverlay}))
    }
    // console.log(dateOverlay);

    // const handleRemoteSort = (col,dir)=>{
    //     dispatch(getOrders({page,size,col,dir,searchValue}))
    //     // console.log(col);
    // }

    // date range
    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);


    let fromDate = new Date(date[0]?.startDate)
    let toDate = new Date(date[0]?.endDate)

    // const dateRangeToggle = ()=>{
    //     dispatch(openHandler({dateShow:!dateOverlay}))
    // }

    const handleDateRangeChange = (e)=>{
        setdate([e.selection])
        let selectedDate = e?.selection
        setSelectedDate(selectedDate)
        dispatch(getOrders({page,size,col,dir,searchValue,selectedDate,orderType}))
        // console.log(selectedDate);
    }
    // console.log(date[0]);
    // console.log(curDate);

    const handleSearch = ()=>{
        dispatch(getOrders({searchValue})).then( ()=> dispatch(resetPage()))
    }
    const handleReset = ()=>{
        dispatch(getOrders({page,size})).then( ()=> dispatch(resetPage()))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])


    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };
    
    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'id'},
    //     // {name:'Customer Name'},
    //     {name:'restaurant_name'},
    //     {name:'created at'},
    //     {name:'delivered_at'},
    //     {name:'order_rating'},
    //     {name:'courier_rating'},
    //     {name:'original_price'},
    //     {name:'final_price'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null);
    // }

    const {handleRemoteSort, icon} = useRemoteSort(getOrders,dispatch,page,size,searchValue,orderType,selectedDate)



     // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['id', 'CourierName', 'CourierNumber', 'CreatedAt', 'DeliveredAt', 'orderStatus','deliveryCharges', 'CustomerName', 'CustomerNumber', 'CustomerAddress','BranchName','Actions']);
    
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['id', 'CourierName', 'CourierNumber', 'CreatedAt', 'DeliveredAt', 'orderStatus','deliveryCharges', 'CustomerName', 'CustomerNumber', 'CustomerAddress','BranchName','Actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    return ( 
        <article className="orders-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('orders.title')}</h1>
            </section>

            <section className={`orders-control-wrapper flex ${localStorage?.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'} flex-wrap justify-center lg:justify-between items-center mt-5 w-full relative`}>
                
                <div className={`orders-filter-wrapper flex ${localStorage?.getItem('i18nextLng') === 'ar' ? '[&>div]:ml-3' : '[&>div]:mr-3'} justify-evenly`}>
                    <div className='date-wrapper '>
                        <Button className='bg-white text-[#333] font-semibold rounded-md py-[10px]' onClick={handleButton}>
                            <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? new Date(fromDate.getTime() + (fromDate?.getTimezoneOffset() * -60000)).toISOString().slice(0,10) + " - " + new Date(toDate.getTime() + (toDate?.getTimezoneOffset() * -60000)).toISOString().slice(0,10) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button>
                        </Button>

                        {dateOverlay ? 
                            <div dir='ltr' className="absolute z-[9999999] left-[30%]  -translate-x-1/2 top-8 bg-white p-4 rounded-lg">
                                <DateRangePicker
                                    onChange={handleDateRangeChange}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={date}
                                    direction="vertical"
                                    />
                            </div> : null}
                        
                    </div>
                    
                    {/* <div>
                        <FormControl sx={{ minWidth: 120,border:0,m:0,borderRadius:'6px',backgroundColor:'white','& fieldset': { borderColor: 'primary.main',border:'none' } }} size="small">
                            <InputLabel id="select-region">Region</InputLabel>
                            <Select
                                labelId="select-region"
                                id="demo-select-region"
                                value={region}
                                label="Region"
                                onChange={handleRegionChange}
                            >
                                <MenuItem value= "">None</MenuItem>
                                <MenuItem value={10}>EG</MenuItem>
                                <MenuItem value={20}>SA</MenuItem>
                                <MenuItem value={30}>UAE</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}

                    <div>
                        <FormControl sx={{ textTransform:'capitalize' ,minWidth: 130,border:0,m:0,borderRadius:'6px',backgroundColor:'white','& fieldset': { borderColor: 'primary.main',border:'none' } }} size="small">
                            {orderType === null ? <InputLabel shrink={false} id="order-select-type" className='text-[#333] font-semibold font-playfair capitalize text-sm'>{t('orders.type')}</InputLabel> : null}
                            <Select
                                labelId="select-type"
                                id="demo-select-type"
                                value={orderType}
                                label="Type"
                                onChange={handleTypeChange}
                                displayEmpty
                            >
                                <MenuItem value= "" className='text-[#333] font-semibold font-playfair'>All</MenuItem>
                                {orders?.ordersType?.data?.map( item=>(
                                    <MenuItem key={item?.id} value={item?.name} className='capitalize [&>span]:capitalize text-[#333] font-semibold font-playfair'><span className='capitalize'>{item?.name?.toLowerCase()?.replace(/_/g, " ")}</span></MenuItem>
                                ) )}
                            </Select>
                        </FormControl>
                    </div>

                    {/* <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div> */}
                </div>

                <div className={`orders-search-wrapper flex ${localStorage?.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3`}>
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{py : '9px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={`${localStorage?.getItem('i18nextLng') === 'ar' ? 'ابحث في الطلبات' : 'Search Orders...'}`}
                            onChange={(e)=>{
                                setsearchValue(e.target.value.trim())
                                dispatch(resetPage())
                                if (!e.target.value) {
                                    handleReset();
                                    dispatch(resetPage())
                                }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>

                    <div className="flex">
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                        {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                    </div>
                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length === 0 ? 
                    <div className='p-8'><NoDataEmoji /></div>
                : 
                    <>
                        <DataTable
                            direction={localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage = {size}
                            paginationRowsPerPageOptions = {[10,50,100]}
                            paginationServer
                            paginationTotalRows={orders?.orders?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage = {true}
                            sortServer
                            sortIcon={icon}
                            onSort={handleRemoteSort}
                            onRowClicked={(data)=>navigate('orderdetails',{state:{id:data?.id}})}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText : t('common.paginationRowText'),
                                    rangeSeparatorText : t('common.of')
                                }
                            }
                        />
                    </>
            }

            {orders?.loading ?
                <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                    <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                </div> : null}
            </section>
        </article>
    );
}

export default Orders;