import { Button, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import SubscribeModal from "./subscripeModal";
import Overlay from '../../../Components/Common/overlay'
import { useDispatch } from "react-redux";
import { getPackages } from "../../../Components/Redux/Slice/Packages/packages";

const TakeinOffers = ({t,packages}) => {
    
    const [id, setid] = useState(null)
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = ()=>setopenModal(true)
    const handleCloseModal = ()=>setopenModal(false)
    const dispatch = useDispatch()

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(12)

    useEffect(() => {
        dispatch(getPackages({page,size}))
    }, [dispatch,page,size])

    const handlePageChange = (e,page) => {
        setpage(page);
    };

    
    return ( 
        <article className="dp-takein-offers-wrapper h-[71vh] relative">
            <section className="dp-offers-card-wrapper grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-h-[520px] overflow-hidden overflow-y-auto">
                {packages?.packages?.data?.map( (card,index)=>(
                        <div key={card?.id} className="bg-white rounded-lg p-3 [&>p]:mb-3 [&>p]:font-semibold">
                            <p className="text-lg text-[#333] capitalize">{localStorage.getItem('i18nextLng') === 'ar' ? card?.packageNameAr : card?.packageName}</p>
                            <p className="text-mainColor">{card?.discountValue} {t('common.sar')}</p>
                            <Button  onClick={()=>{
                                handleOpenModal()
                                setid(card?.id)
                            }} disabled={card?.isSubscribed} className="disabled:opacity-30 disabled:cursor-not-allowed disabled:pointer-events-auto w-full capitalize text-center text-white bg-mainGreen">{card?.isSubscribed ? t('dp.subscriped') : t('dp.subscripe')}</Button>
                        </div>
                    ) )
                }
            </section>

            <section className="pagination-wrapper absolute bottom-0 left-0 z-20">
                <Pagination dir='ltr' count={packages?.packages?.meta?.totalPages} defaultPage={1} onChange={handlePageChange} />
            </section>
            
                <SubscribeModal open={openModal} close={handleCloseModal} t={t} id={id} />

                {packages?.loading ? <Overlay /> : null}
        </article>
    );
}

export default TakeinOffers;