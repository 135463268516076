import { ListItemButton, MenuItem, Select, } from "@mui/material";
import setting from '../../Assets/imgs/setting.svg'
// import notify from '../../Assets/imgs/notification.svg'
import profile from '../../Assets/imgs/profile.svg'
// import { useState } from "react";
// import DropDown from "../Dropdown-Btn/dropdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const TopHeader = () => {
    const {t,i18n} = useTranslation()

    const changeLanguage = (lang)=>{
        i18n.changeLanguage(lang?.target.value)
        // console.log(lang?.target.value);
    }

    const headerBtn = [
        {name:'setting', src:setting, color:"#5CAC7D",link:'/dashboard/settings'},
        // {name:'notification', src:notify, color:"#C6345C",link:'/'},
        {name:'profile', src:profile, color:"#442B7E",link:'/dashboard/profile'},
    ]

    return ( 
        <div className="topHeader">
            <ul className={`flex flex-wrap flex-row-reverse items-start ${localStorage?.getItem('i18nextLng') === 'ar' ? '[&>li]:mr-1 sm:[&>li]:mr-3' : '[&>li]:ml-1 sm:[&>li]:ml-3'}`}>
                <li>
                    <Select 
                        className="bg-mainColor text-white [&>div]:p-2 w-10 h-10 text-center [&>svg]:hidden text-sm" 
                        sx={{'& fieldset': {border:0}}}
                        defaultValue={(localStorage?.getItem('i18nextLng') === 'en-US' || localStorage?.getItem('i18nextLng') === 'en' || !localStorage.getItem('i18nextLng')) ? 'en' : 'ar'} 
                        onChange={(lang)=>changeLanguage(lang)}
                    >
                        <MenuItem value={'en'}>EN</MenuItem>
                        <MenuItem value={'ar'}>AR</MenuItem>
                    </Select>
                </li>
                {headerBtn?.map(item=>{
                    return(

                        <li key={item.name} className={`rounded-md [&>a>div>img]:w-[15px] [&>a>div>img]:h-[15px] sm:[&>a>div>img]:w-full sm:[&>a>div>img]:h-full`} style={{backgroundColor : item.color}}>
                            <Link to={item.link}>
                                <ListItemButton className="px-3 py-2 sm:px-4 sm:py-2">
                                    <img src={item.src} alt={item.name} />
                                </ListItemButton>
                            </Link>
                        </li>

                    )
                })}
                
                {/* <li>
                    <DropDown />
                </li> */}
            </ul>
        </div>
    );
}

export default TopHeader;